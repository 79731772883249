module.exports = {
  siteTitle: 'Katka Mužíková Photo', // <title>
  manifestName: 'Katka Mužíková Photo',
  manifestShortName: 'MK Photo', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#2e3842',
  manifestThemeColor: '#2e3842',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  heading: 'Katka Mužíková Photo',
  subHeading: 'Svatební a rodinný fotograf',
};
