import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';
import config from '../../config';
import { FaChild, FaDog, FaGlassCheers, FaUsers } from 'react-icons/fa';
import { Seo } from '../components/Seo';
import { StaticImage } from 'gatsby-plugin-image';

const IndexPage = () => (
  <Layout>
    <Seo
      title="Svatební a rodinný fotograf Beskydy"
      keywords='rodinné foceni, svatební fotograf, lifestyle fotograf, beskydy, moravskoslezský kraj'
      description='Svatební a rodinné focení v Beskydech a Moravskoslezském kraji.'
    />

    <section id='banner'>
      <StaticImage
        src='../assets/images/banner.png'
        alt={config.subHeading}
        placeholder='blurred'
        objectFit='cover'
        className='static-image banner-image'
        objectPosition='50% 50%'
      />
      <div className="banner-gradient"/>
      <div className='inner'>
        <h2>{config.heading}</h2>
        <h1>{config.subHeading}</h1>
      </div>
      <Scroll type='id' element='one'>
        <a href='#one' className='more'>
          <span data-nosnippet>Neboj se a scrolluj</span>
        </a>
      </Scroll>
    </section>

    <section id='one' className='wrapper style1 special'>
      <div className='inner'>
        <header className='major'>
          <p>
            Ahoj, jmenuji se Katka a vítám vás na svých stránkách. </p>
          <p>
            Mám ráda přirozené fotky a světlo. Nejhezčí rodinné fotky vznikají venku,
            kdy jste vy i vaše děti uvolnění a zkrátka sami sebou. Nebudu vás
            nutit do cíleného pózování a křečovitých úsměvů. Zachytím vás
            takové, jací jste. Spíše se společně projdeme lesem, loukou, zdoláme
            kopec nebo zavítáme na dětské hřiště a to by bylo, aby nevznikly
            hezké fotky.
          </p>
        </header>
        <ul className='icons major'>
          <li>
            <Scroll type='id' element='family-photo'>
              <a href='#family-photo' className='icon solid major style1' title="rodinné focení v beskydech">
                <FaUsers />
              </a>
            </Scroll>
          </li>
          <li>
            <Scroll type='id' element='portraits-photo'>
              <a href='#portraits-photo' className='icon solid major style1' title="focení portrétů">
                <FaChild />
              </a>
            </Scroll>
          </li>
          <li>
            <Scroll type='id' element='wedding-photo'>
              <a href='#wedding-photo' className='icon solid major style1' title="svatební fotograf">
                <FaGlassCheers />
              </a>
            </Scroll>
          </li>
          <li>
            <Scroll type='id' element='dog-photo'>
              <a href='#dog-photo' className='icon solid major style1' title="focení zvířat v beskydech">
                <FaDog />
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
    </section>

    <section id='two' className='wrapper alt style2'>
      <section className='spotlight' id='family-photo'>
        <div className='image'>
          <Link to='/rodinne'>
            <StaticImage
              src='../assets/images/pic01.jpg' alt='Rodinné focení v beskydech'
              placeholder='blurred'
            />
          </Link>
        </div>
        <div className='content'>
          <h2>
            Rodinné focení
          </h2>
          <p>Nechte fotografie vyprávět váš rodinný příběh. Okamžiky strávené s rodinou a krásu přírody považuji za to nejkrásnější co může život nabídnout. Tak co to spojit dohromady?</p>
          <Link to='/rodinne'>více informací o rodinném focení ></Link>
        </div>
      </section>

      <section className='spotlight' id='portraits-photo'>
        <div className='image'>
          <Link to='/portrety'>
             <StaticImage
              src='../assets/images/pic03.jpeg' alt='Portréty'
            placeholder='blurred'
             />
          </Link>
        </div>
        <div className='content'>
          <h2>Portréty</h2>
          <p>
            Jste jedineční a originální, a to je právě ta esence, kterou by měla správná portrétní fotografie obsahovat.
          </p>
          <Link to='/portrety'>více informací o focení portrétů ></Link>
        </div>
      </section>
      <section className='spotlight' id='wedding-photo'>
        <div className='image'>
          <Link to='/svatby'>
            <StaticImage
              src='../assets/images/pic02.jpg' alt='Svatební fotka nevěsty'
              placeholder='blurred'
            />
          </Link>
        </div>
        <div className='content'>
          <h2>Svatební fotograf</h2>
          <p> Užijte si svůj jedinečný den naplno a mně nechte zachytit dění kolem.</p>
          <Link to='/svatby'>více informací o focení svateb ></Link>
        </div>
      </section>
      <section className='spotlight' id='dog-photo'>
        <div className='image'>
          <Link to='/zvirata'>
             <StaticImage
            src='../assets/images/pic04.jpg' alt='focení zvířat'
            placeholder='blurred'
             />
          </Link>
        </div>
        <div className='content'>
          <h2>Focení zvířat</h2>
          <p>
            Udělat pěknou fotku domácího mazlíčka vyžaduje práci s dynamikou zvířete společně s výběrem správného detailu.
          </p>
          <Link to='/zvirata'>více informací o focení zvířat ></Link>
        </div>
      </section>
    </section>
  </Layout>
);

export default IndexPage;
